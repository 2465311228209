// export const BASE_URL = "https://api-shadow-padel-v1.0.premcoprecast.com/";
export const BASE_URL = "https://api-reservationsystem-dev.premcoprecast.com/";
// export const BASE_URL = "https://RPS-api-top-sports.adminssw.com/";

// import { getDeviceName, getBrowserName } from "@/utils/functions";
export const USER_VERSION = {
  userPlatFormToken: "PFT-3",
  userFirebaseToken: "descTop",
  userVersionNumber: "5.0",
  deviceNameEn: "dell",
  deviceVersion: "windows 10",
};

export const USER_TYPE = {
  employee: "employee",
  client: "client",
};

export const STATUS = {
  SUCCESS: 200, // success operation
  INVALID_TOKEN: 498, //invalid token
  CATCH: 500, // catch
  NO_CONTENT: 204, // no data
  ERROR: 406, // validation
  VERSION: 306, // error version
};

export const HOME_PAGE = "/places/places";
export const PAGE_SIZE = 16;
export const IMPOSSIBLE_TOKEN = 0;
export const SERVICE_TYPE_NAME_MIN_LENGTH = 3;
export const SERVICE_TYPE_NAME_MAX_LENGTH = 50;
export const SERVICE_NAME_DESC = 500;
export const SCIENTIFIC_DEGREE_NAME_MIN_LENGTH = 3;
export const SCIENTIFIC_DEGREE_NAME_MAX_LENGTH = 50;
export const SCIENTIFIC_DEGREE_NAME_DESC = 500;
export const GENERAL_SPECIALIATY_NAME_MIN_LENGTH = 3;
export const GENERAL_SPECIALIATY_NAME_MAX_LENGTH = 50;
export const GENERAL_SPECIALIATY_NAME_DESC = 500;
export const SPECIAL_SPECIALIATY_NAME_MIN_LENGTH = 3;
export const SPECIAL_SPECIALIATY_NAME_MAX_LENGTH = 50;
export const SPECIAL_SPECIALIATY_NAME_DESC = 500;
export const SERVICE_MIN_LENGTH = 3;
export const SERVICE_MAX_LENGTH = 50;
export const SERVICE_DESC = 500;

export const NAME_MIN_LENGTH = 3;
export const NAME_MAX_LENGTH = 50;
export const TEXT_MAX_LENGTH = 500;
export const TEXT_MAX_LENGTH_SUPER = 1000000;
export const MAX_ROWS_TEXTAREA = 100;

export const NEWS_MEDIA_TYPE_TOKEN_IMAGE = "MTT-1";
export const NEWS_MEDIA_TYPE_TOKEN_VIDEO = "MTT-2";
export const VIDEO_EXTENSIONS = [
  "mov",
  "avi",
  "wmv",
  "mp4",
  "m4p",
  "m4v",
  "ogg",
  "mpg",
  "mp2",
  "mpeg",
  "mpe",
  "mpv",
  "3gp",
  "flv",
];

export const MainConstansModuleImport = {
  STATUS,
  PAGE_SIZE,
  NAME_MIN_LENGTH,
  NAME_MAX_LENGTH,
  TEXT_MAX_LENGTH,
};

